import Link from "next/link";
import React, { useState, useEffect } from "react";
import { AnimateSharedLayout, motion } from "framer-motion";
import { styled } from "../lib/styled";
import {
  layout,
  grid,
  layoutProps,
  gridProps,
  flexbox,
  flexboxProps,
  space,
  spaceProps,
  color,
  colorProps,
} from "styled-system";
import Text from "./Text";
import { ArrowRight } from "./Icons";
import Flex from "./Flex";
import Spacer from "./Spacer";

function Card({ title, description, backgroundColor, href }) {
  return (
    <Link href={href}>
      <Wrapper
        p={["25px"]}
        backgroundColor={backgroundColor}
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.97 }}
      >
        <Flex justifyContent="space-between">
          <Flex mr="20px">
            <Text fontSize={[3, "22px"]} fontWeight="700" color="white">
              {title}
            </Text>
          </Flex>
          <ArrowRight fill="white" />
        </Flex>
        <Spacer mt="10px" />
        <Text color="white">{description}</Text>
      </Wrapper>
    </Link>
  );
}

type Wrapper = layoutProps & gridProps & spaceProps & colorProps;

const Wrapper = styled(motion.div)<Wrapper>`
  cursor: pointer;
  ${layout}
  ${grid}
  ${space}
  ${color}
  height: 100%;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
`;

export default Card;
