import { Text, Flex, Spacer, Carousel, Grid } from "../components";
import Layout from "../components/Layout";
import { Curriculum, Aanbod } from "../components/Icons";
import * as Icons from "../components/Icons";
import BasicMeta from "../components/meta/BasicMeta";
import OpenGraphMeta from "../components/meta/OpenGraphMeta";
import { motion } from "framer-motion";
import Card from "../components/Card";
import { getAllServices } from "../lib/graphcms/services";
import { bg } from "date-fns/locale";
export default function Index({ services }) {
  return (
    <Layout cta>
      <BasicMeta url={"/"} />
      <OpenGraphMeta url={"/"} />
      <Spacer mt={[2, 3]} />

      <Flex
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDirection={["column", "row"]}
        width={["80vw", "90vw", "70vw"]}
        maxWidth="1100px"
      >
        <Grid gridTemplateColumns={["1fr", "1fr 3fr"]}>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: { type: "spring", duration: 1 },
            }}
            exit={{
              scale: 0.8,
              opacity: 0,
              transition: { type: "spring", duration: 0.2 },
            }}
            style={{
              position: "relative",
              borderRadius: "10px",
              overflow: "hidden",
              minHeight: "250px",
              boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundImage: "url(/images/homepage-min-4-tiny.jpg)",
                filter: "blur(10px)",
                backgroundSize: "cover",
                backgroundPosition: "28%",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundImage: "url(/images/homepage-min-4.jpg)",
                // filter: "blur(10px)",
                backgroundSize: "cover",
                backgroundPosition: "28%",
              }}
            />

            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                opacity: 0.15,
                backgroundColor: "white",
              }}
            />
          </motion.div>
          <Flex flexDirection="column" flex="1" pl={[0, 4]} mt={[2, 0]}>
            <Text
              color="pink.0"
              fontSize={[5, 5, 6]}
              alignSelf="start"
              fontWeight="600"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,
                transition: { type: "spring", bounce: 0.6 },
              }}
              exit={{
                scale: 0.8,
                opacity: 0,
                transition: { type: "spring", duration: 0.2 },
              }}
            >
              Hallo!!
            </Text>
            <Spacer mt={[2, 3]} />
            <Text
              fontSize={[2, 2, 2, "19px", 2]}
              alignSelf="start"
              textAlign="justify"
              initial={{ y: 10, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { type: "spring", duration: 0.7, delay: 1 / 5 },
              }}
              exit={{
                y: 0,
                opacity: 0,
                transition: { type: "spring", duration: 0.2 },
              }}
            >
              Gezond leven komt uiteindelijk altijd op hetzelfde neer: de juiste
              balans vinden. Mijn visie is dat gezond eten niet hetzelfde is als
              diëten. Bij gezonde voeding tellen we geen calorieën, worden
              porties niet gewogen... dus in een notendop je moet je niets
              ontzeggen maar je leert gezonde gewoonten aan en maakt gezondere
              keuzes.
            </Text>
            <Spacer mt={[2, 3]} />

            <Text
              fontSize={[2, 2, 2, "19px", 3]}
              alignSelf="start"
              textAlign="justify"
              initial={{ y: 10, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { type: "spring", duration: 0.7, delay: 2 / 5 },
              }}
              exit={{
                y: 0,
                opacity: 0,
                transition: { type: "spring", duration: 0.2 },
              }}
            >
              Door stap per stap te werken aan die verandering is het een
              makkelijke manier om het op lange termijn vol te houden en er ook
              echt je levensstijl van te maken. Eten moet leuk en vooral ook
              lekker blijven, en ik ben de persoon die je hier met veel plezier
              bij helpt.
            </Text>
          </Flex>
        </Grid>
      </Flex>
      <Spacer mt={[3, 4]}> </Spacer>
      <Carousel>
        {services.map(({ title, description, bg, icon, page }, index) => (
          <Card
            key={index}
            title={title}
            description={description}
            backgroundColor={bg.color.hex}
            href={"/" + page?.slug}
          />
        ))}
      </Carousel>
      <Spacer mt={[2, 3]}> </Spacer>
    </Layout>
  );
}

export async function getStaticProps({ preview = false }) {
  const services = (await getAllServices(preview)) || [];
  return {
    props: { services, preview },
  };
}
